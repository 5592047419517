import React from 'react';
import './stars.css';

const Stars = () => {
    return (
        <>
            <div className='stars'></div>
            <div className='twinkling'></div>
        </>
    );
}

export default Stars;